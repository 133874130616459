<template>
  <v-card class="mt-2" flat>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-btn
        color="info"
        @click="
          edit = false;
          receiptDialog = true;
        "
      >
        Uusi kulu
      </v-btn>
    </v-card-title>

    <!-- Search receipts-->
    <search-receipts @searchreceipts="getDataFromApi"></search-receipts>

    <!-- RECEIPTS -->
    <v-data-table
      :search="search"
      :headers="headers"
      :items="receipts"
      :options.sync="options"
      show-expand
      :footer-props="globalValues.footerProps"
      :items-per-page="getDefaultTableRows()"
      :server-items-length="totalLength"
      :loading="loading"
      item-key="_id"
    >
      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-data-table
            class="custom-data-table"
            dense
            :headers="productHeaders"
            :items="item.products"
            :items-per-page="100"
            :footer-props="customFooterProps"
          >
            <!-- Amount -->
            <template #[`item.amount`]="{ item }">
              <span>{{ formatCurrency(item.amount) }}</span>
            </template>

            <!-- Taxpr -->
            <template #[`item.taxpr`]="{ item }">
              <span>{{ item.taxpr }} %</span>
            </template>

            <!-- Taxes total -->
            <template #[`item.totalVat`]="{ item }">
              <span>{{ formatCurrency(calcTaxTotal(item)) }}</span>
            </template>

            <!-- Total amount -->
            <template #[`item.total`]="{ item }">
              <span>{{ formatCurrency(item.count * item.amount) }}</span>
            </template>
          </v-data-table>
        </td>
      </template>

      <!-- Name -->
      <template #[`item.name`]="{ item }">
        <span class="font-weight-bold">{{ item.name }}</span>
      </template>

      <!-- ReceiptType -->
      <template #[`item.receiptType`]="{ item }">
        <span>{{ getReceiptType(item.receiptType) }}</span>
      </template>

      <!-- Is recurrent -->
      <template #[`item.recurrency.isRecurrent`]="{ item }">
        <span>{{ formatBoolean(item.recurrency.isRecurrent) }}</span>
      </template>

      <!-- Amount -->
      <template #[`item.amount`]="{ item }">
        <span class="font-weight-bold success--text">{{
          formatCurrency(countTotalPrice(item.products))
        }}</span>
      </template>

      <!-- Date -->
      <template #[`item.date`]="{ item }">
        <span>{{ formatDate(item.date) }}</span>
      </template>

      <!-- TaxDeductible -->
      <template #[`item.taxDeductible`]="{ item }">
        <span v-if="item.taxDeductible" class="font-weight-bold success--text">{{
          formatBoolean(item.taxDeductible)
        }}</span>
        <span v-if="!item.taxDeductible" class="font-weight-bold error--text">{{
          formatBoolean(item.taxDeductible)
        }}</span>
      </template>

      <!-- Outgoing payments -->
      <template #[`item.outgoingPayments`]="{ item }">
        <span v-if="item.outgoingPayments.inUse" class="font-weight-bold success--text"
          >Käytössä</span
        >
        <span v-else class="font-weight-bold error--text">Ei käytössä</span>
      </template>

      <!-- Actions -->
      <template #[`item.actions`]="{ item }">
        <div class="action-grid-container">
          <v-icon
            title="Muokkaa"
            small
            @click="
              formatReceipt(item);
              edit = true;
              receiptDialog = true;
            "
            >mdi-pencil</v-icon
          >
          <v-icon title="Poista" small @click="deleteItem(item)">mdi-delete</v-icon>

          <v-icon
            :class="{ 'info--text': hasItems(item.files) }"
            title="Lataa tosite"
            small
            @click="
              receiptId = item._id;
              showFileDialog = true;
            "
            >mdi-paperclip</v-icon
          >
        </div>
      </template>

      <template #no-data>
        <h2>Ei kuluja</h2>
      </template>
    </v-data-table>

    <!-- Dialog add or edit receipt -->
    <receipt-dialog
      v-model="receiptDialog"
      :edit="edit"
      @savereceipt="saveReceipt"
      @createreceipt="create"
    ></receipt-dialog>

    <!-- File dialog -->
    <File
      v-model="showFileDialog"
      :document-id="receiptId"
      title="Lataa tosite"
      document="receipt"
      bucket="vuokranet-tositteet"
      model="receipt"
      @sendnewfiles="replaceFilesArray"
    ></File>
  </v-card>
</template>

<script>
import mixins from "../../mixins/mixins";
import File from "../File";
import validations from "@/validations";
import SearchReceipts from "./SearchReceipts.vue";
import ReceiptDialog from "./ReceiptDialog.vue";
import { mapActions, mapState, mapMutations } from "vuex";
import globalValues from "@/configs/globalValues";

export default {
  components: {
    ReceiptDialog,
    File,
    SearchReceipts,
  },

  mixins: [mixins],

  props: {
    source: {
      type: String,
      default: "apartment",
    },
  },

  data() {
    return {
      options: {},
      edit: false,
      receiptId: "",
      receiptDialog: false,
      showFileDialog: false,
      allHeaders: [
        { text: "Nimi", value: "name" },
        { text: "Tyyppi", value: "receiptType" },
        // { text: "Toistuva", value: "recurrency.isRecurrent" },
        { text: "Kuvaus", value: "description" },
        { text: "Summa sis. alv", value: "amount" },
        { text: "Kulun päiväys", value: "date" },
        { text: "Vähennyskelpoinen", value: "taxDeductible" },
        { text: "Lähtevät maksut", value: "outgoingPayments" },
        { text: "Toiminnot", value: "actions", sortable: false },
      ],
      productHeaders: [
        { text: "Tuote", value: "name", sortable: false },
        { text: "Hinta sis alv.", value: "amount", sortable: false },
        { text: "Kpl", value: "count", sortable: false },
        { text: "Arvonlisävero", value: "taxpr", sortable: false },
        { text: "Verot yhteensä", value: "totalVat", sortable: false },
        { text: "Yhteensä", value: "total", sortable: false },
      ],
      customFooterProps: {
        "items-per-page-options": [5, 10],
        showFirstLastPage: true,
        firstIcon: "mdi-arrow-collapse-left",
        lastIcon: "mdi-arrow-collapse-right",
        prevIcon: "mdi-minus",
        nextIcon: "mdi-plus",
      },
      validations,
      globalValues,
    };
  },

  computed: {
    ...mapState("account", ["currentUser"]),
    ...mapState("receipt", [
      "receipts",
      "totalLength",
      "loading",
      "startDate",
      "endDate",
      "search",
      "receiptType",
    ]),
    ...mapState("browsehistory", [
      "dataTablePage",
      "getPreviousPage",
      "itemsPerPage",
      "mainSearchValue",
    ]),

    headers() {
      return this.allHeaders.filter((h) => {
        if (h.value === "outgoingPayments") {
          return (
            this.currentUser.currentAccount.settings.subtractReceiptsFromOutgoing ||
            this.currentUser.currentAccount.settings.createReceiptsPayments
          );
        } else {
          return true;
        }
      });
    },
  },

  watch: {
    options: {
      handler() {
        if (this.getPreviousPage) {
          if (this.mainSearchValue != "") this.search = this.mainSearchValue;
          this.options.page = this.dataTablePage;
          this.options.itemsPerPage = this.itemsPerPage;
        }
        this.saveTableOptions({ ...this.options });
        this.getDataFromApi();
      },
      deep: true,
    },

    totalLength() {
      this.options.page = 1;
    },
  },

  methods: {
    ...mapActions("receipt", ["getReceipts", "createReceipt", "updateReceipt", "deleteReceipt"]),
    ...mapMutations("browsehistory", ["saveTableOptions", "saveMainSearchTerm"]),
    ...mapMutations("receipt", [
      "setLoading",
      "setReceiptToState",
      "setShowSums",
      "replaceFiles",
      "setCurrentReceipt",
    ]),

    async getDataFromApi() {
      this.setShowSums(false);
      this.setLoading(true);

      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      // Save main search term
      this.saveMainSearchTerm(this.search);

      let search = this.search.trim().toLowerCase();

      let url =
        this.source === "general"
          ? `/api/v1/receipt/getGeneralReceipts?sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&search=${search}`
          : `/api/v1/receipt/getApartmentReceipts/${this.$route.params.id}?sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&search=${search}`;

      await this.getReceipts({
        url,
        dates: { startDate: this.startDate, endDate: this.endDate },
        receiptType: this.receiptType,
      });
    },

    countTotalPrice(products) {
      let totalAmount = 0;

      products.forEach((el) => {
        totalAmount += el.amount * el.count;
      });

      return totalAmount;
    },

    async create() {
      try {
        await this.createReceipt();
        this.receiptDialog = false;
        this.showPopup("Kulu luotu", "success");
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    async saveReceipt() {
      try {
        this.setShowSums(false);
        await this.updateReceipt();
        this.receiptDialog = false;
        this.showPopup("Kulu tallennettu", "success");
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    async deleteItem(item) {
      let answer = confirm(
        `Haluatko varmasti poistaa kulun ${item.name}, ${this.formatDate(item.date)}?`
      );

      if (answer) {
        try {
          this.setShowSums(false);

          await this.deleteReceipt(item._id);
          this.showPopup("Kulu poistettu", "success");
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },

    calcTaxTotal(prod) {
      return this.roundDecimals(
        prod.amount * prod.count - (prod.amount * prod.count) / (1 + prod.taxpr / 100)
      );
    },

    formatReceipt(item) {
      item.date = new Date(item.date).toISOString().substring(0, 10);

      if (item.outgoingPayments.subtractDate) {
        item.outgoingPayments.subtractDate = new Date(item.outgoingPayments.subtractDate)
          .toISOString()
          .substring(0, 7);
      }
      this.setCurrentReceipt(item);
    },

    replaceFilesArray(receipt) {
      this.replaceFiles(receipt);
    },
  },
};
</script>

<style scoped></style>
